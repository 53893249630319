import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Button, Typography, useTheme, } from '@mui/material';
import { useMemo, useRef, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import isPropValid from '@emotion/is-prop-valid';
import AvatarImage from '@shared/ui/display/AvatarImage';
import Icon from '@shared/ui/icons/Icon';
import Logo from '@shared/ui/display/Logo';
import StatusBadge from '@shared/ui/badges/StatusBadge';
import CircleButton from '@shared/ui/buttons/CircleButton';
import { ReactComponent as ToggleSidebarIcon } from '@icons/wolfkit-light/line-3-light.svg';
import { ReactComponent as NotificationsIcon } from '@icons/wolfkit-light/bell-light.svg';
import { useAppDispatch, useAppSelector } from '@app/store/Hooks';
import { toggleNavSidebar } from '@store/slices/application';
import useAppNavigation from '@utils/hooks/useAppNavigation';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { formatUSD } from '@shared/lib/numbers';
import { ConnectExchangeButton } from '@features/exchange';
import { IsDefined } from '@utils/js-ts';
import TopbarProfileMenu from './components/TopbarProfileMenu';
import TopbarSearch from './components/TopbarSearch';
const TopbarContainer = styled('header', { shouldForwardProp: (prop) => isPropValid(prop) })(props => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    boxSizing: 'border-box',
    height: props.theme.spacing_sizes.xs * 9,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${props.theme.spacing_sizes.xs * 2}px ${props.theme.spacing_sizes.s * 5}px
    ${props.theme.spacing_sizes.xs * 2}px ${props.theme.spacing_sizes.xs * 2}px`,
    backgroundColor: props.inverted ?
        props.theme.customColors.portfolio.hover :
        props.theme.customColors.surface.surface,
    borderBottom: `1px solid ${props.theme.customColors.surface.outline}`,
    zIndex: 1,
}));
const SideContainer = styled(ContainerRow, { shouldForwardProp: propName => propName !== 'justify' })(props => ({
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: props.justify || 'start',
    flex: 1,
}));
const ToggleSidebarButton = styled(Button)(props => ({
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    padding: props.theme.spacing_sizes.xs * 1.25,
    minWidth: 'unset',
    border: 'unset',
}));
const LogoButton = styled(ContainerRow)(props => ({
    width: 130,
    cursor: 'pointer',
    height: 'auto',
    alignItems: 'center',
    marginLeft: props.theme.spacing_sizes.xs,
    gap: 9,
}));
const LogoText = styled(Typography)(props => ({
    fontSize: `${props.theme.spacing_sizes.l}px`,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: `${props.theme.spacing_sizes.s * 5}px`,
    letterSpacing: '-0.456px',
    userSelect: 'none',
}));
const SearchInputContainer = styled.div((props) => ({
    width: '100%',
    maxWidth: 320,
    marginLeft: props.theme.spacing_sizes.xs * 4,
}));
const BalanceContainer = styled(ContainerColumn)(props => ({
    width: 'auto',
    justifyContent: 'center',
    gap: props.theme.spacing_sizes.xs,
}));
const BalanceRow = styled(ContainerRow)(props => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    justifyContent: props.justify || 'center',
    marginBottom: props.topRow ? 3 : 0,
    gap: props.theme.spacing_sizes.s,
}));
const Balance = styled(Typography, { shouldForwardProp: propName => propName !== 'inverted' })(props => ({
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    color: props.inverted ?
        props.theme.customColors.portfolio.onHover :
        props.theme.palette.text.primary,
}));
const BalanceDifference = styled(Typography, { shouldForwardProp: propName => propName !== 'negative' })(props => ({
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    color: props.negative ? props.theme.palette.secondary.main : props.theme.palette.success.main,
    marginLeft: props.theme.spacing_sizes.s,
    backgroundColor: props.theme.customColors.surface.surface,
    borderRadius: 3,
    padding: '1px 6px',
}));
const ActiveText = styled(Typography, { shouldForwardProp: propName => propName !== 'inverted' && propName !== 'textHeight' })(props => ({
    cursor: 'pointer',
    userSelect: 'none',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: props.textHeight || '32px',
    color: props.inverted ?
        props.theme.customColors.portfolio.onHover :
        props.theme.palette.primary.main,
}));
const Delimiter = styled.div(() => ({
    width: 1,
    height: 54,
    margin: '0 19px',
    backgroundColor: '#D8E1FB',
}));
const getBalanceTopInvertedStyles = (theme, inverted = false) => (inverted ? ({
    color: theme.customColors.portfolio.hover,
    backgroundColor: theme.customColors.portfolio.onHover,
    '&:hover': {
        backgroundColor: theme.customColors.menu.item.hover,
    },
    '&:active': {
        backgroundColor: theme.customColors.menu.item.active,
    },
}) : ({}));
const BalanceTopUpButton = styled(Button, { shouldForwardProp: propName => propName !== 'inverted' })(props => (Object.assign({ minWidth: 'auto', minHeight: 'auto', padding: '1px 5px', marginLeft: props.theme.spacing_sizes.s, fontSize: 15, fontStyle: 'normal', fontWeight: 500, lineHeight: 'normal' }, getBalanceTopInvertedStyles(props.theme, props.inverted))));
const NotificationsContainer = styled.div(props => ({
    margin: `0 ${props.theme.spacing_sizes.l}px 0 ${props.theme.spacing_sizes.l}px`,
}));
const AvatarButton = styled.button(props => ({
    width: props.theme.spacing_sizes.xs * 5,
    height: props.theme.spacing_sizes.xs * 5,
    cursor: 'pointer',
    borderRadius: '50%',
    border: 'none',
    backgroundColor: props.theme.palette.primary.light,
    padding: 0,
}));
const getCircleButtonInvertedProps = (theme, inverted = false) => (inverted ? ({
    color: theme.customColors.portfolio.onHover,
    backgroundColor: 'rgba(255, 255, 255, 0.25)',
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
    },
    '&:active, &:focus': {
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
    },
}) : ({}));
const CircleButtonStyled = styled(CircleButton)(props => (Object.assign({}, getCircleButtonInvertedProps(props.theme, props.inverted))));
const Topbar = () => {
    var _a;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const { navigate, routes } = useAppNavigation();
    const { user } = useAppSelector(state => state.user);
    const { currentUserProfile } = useAppSelector(state => state.profile);
    const anchorEl = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const invertedView = useMemo(() => (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) === 'TRADER', [currentUserProfile]);
    const onToggleSidebar = () => {
        dispatch(toggleNavSidebar());
    };
    const onLogoClick = () => {
        navigate(routes.ROOT);
    };
    const handleMenuToggle = () => {
        setIsMenuOpen((prev) => !prev);
    };
    const closeProfileMenu = () => {
        setIsMenuOpen(false);
    };
    return (_jsxs(TopbarContainer, { inverted: invertedView, children: [_jsxs(SideContainer, { children: [_jsx(ToggleSidebarButton, { variant: 'plain', onClick: onToggleSidebar, children: _jsx(Icon, { size: 20, color: invertedView ?
                                theme.customColors.portfolio.onHover :
                                theme.palette.text.secondary, IconComponent: ToggleSidebarIcon }) }), _jsxs(LogoButton, { onClick: onLogoClick, role: 'img', children: [_jsx(Logo, { variant: invertedView ?
                                    'inverted' :
                                    'primary' }), _jsx(LogoText, { color: invertedView ?
                                    theme.customColors.portfolio.onHover :
                                    theme.palette.text.secondary, children: t('app_name', { ns: 'common' }) })] }), _jsx(SearchInputContainer, { "aria-label": 'search', children: _jsx(TopbarSearch, {}) })] }), _jsxs(SideContainer, { justify: 'end', children: [_jsxs(BalanceContainer, { children: [_jsx(BalanceRow, { children: _jsx(ActiveText, { inverted: invertedView, textHeight: '19px', children: t('profile.real_summary_balance', { ns: 'common' }) }) }), _jsxs(BalanceRow, { justify: 'end', children: [IsDefined(user) && (_jsx(Balance, { inverted: invertedView, children: formatUSD(user.totalAssetsUsd) })), _jsx(ConnectExchangeButton, { style: {
                                            minHeight: 'unset',
                                            padding: `0px ${theme.spacing_sizes.xs}px`,
                                            fontSize: 12,
                                        }, size: 'small', children: t('profile.connect_exchange', { ns: 'common' }) })] })] }), _jsx(NotificationsContainer, { children: _jsx(StatusBadge, { variant: 'notification', childrenShape: 'circle', children: _jsx(CircleButtonStyled, { inverted: invertedView, Icon: NotificationsIcon }) }) }), _jsx(AvatarButton, { ref: anchorEl, onClick: handleMenuToggle, children: _jsx(AvatarImage, { imageUrl: (_a = currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.profilePicture) === null || _a === void 0 ? void 0 : _a.thumbnail, publicName: currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.displayName }) })] }), _jsx(TopbarProfileMenu, { ref: anchorEl, onClose: closeProfileMenu, isOpen: isMenuOpen })] }));
};
export default Topbar;
